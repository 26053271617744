$phoneWidth:420px;
$tabletWidth:550px;
$pcWidth:1100px;
$primary-color:rgba(43, 43, 43, 1);

/*.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row.right {
  justify-content: flex-end;
}*/

.top-bar-links{
  height:100%;
  padding:0;

  #user-menu, #menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    background-color: $primary-color;
    top: 43px;
    right: 0px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.7);
    max-height: 200px;
    transition: all 0.4s;
    //opacity:1;
    overflow:hidden;
    @media screen and(max-width:$phoneWidth){
      &.hidden{
        //opacity:0;
        max-height:0;
        overflow:hidden;
      }
    }

    .nav-item{
      height:58px;
      font-size:16px;
      display:flex;
      align-items: center;
      justify-content: center;
    }
  }

  #user-menu{
    &.hidden{
      max-height:0;
      overflow:hidden;
    }
    @media screen and(min-width:$tabletWidth){
      top:64px;
    }
  }

  #menu{
    @media screen and(min-width:$phoneWidth){
      box-shadow: none;
      position:relative;
      flex-direction:row;
      align-items:center;
      justify-content: flex-end;
      top:0;
      right:0;
      width:auto;
    }

    .nav-item{
      height:58px;
      font-size:16px;
      display:flex;
      align-items: center;
      justify-content: center;
      @media screen and(min-width:$phoneWidth){
        height:auto;
        font-size:12px;
      }
    }
  }

  #menu-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0px;
    top: 0;
    font-size: 12px;
    height: 100%;
    line-height: 100%;
    cursor: pointer;
    img{
      margin-left:10px;
      margin-right:8px;
      height: 12px;
      vertical-align: bottom;
    }
    #menu-btn{
      @media screen and(min-width:$phoneWidth){
        display:none;
      }
    }

  }

  .banner-links{
    height:100%;
    a {
      margin: 0 8px;
      font-size: 11px;
      text-decoration: none;
      color: #fff !important;
      cursor: pointer;
      @media (min-width: $tabletWidth) {
        margin: 0 18px;
        font-size: 14px;
      }
    }
    form{
      height:100%;
      display:flex;
      flex-direction:row-reverse;
      align-items:center;
      justify-content: flex-end;
    }
  }
}

#welcome-message{
  color:#fff;
  margin-right:34px;
  font-size:10px;
  @media (min-width: $phoneWidth) {
    margin-right: 12px;
  }
}


